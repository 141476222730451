// extracted by mini-css-extract-plugin
export var column = "UserInternetRfpProject_Details_LocationDocuments__column__ETQKP";
export var container = "UserInternetRfpProject_Details_LocationDocuments__container__hDtxa";
export var descriptionContent = "UserInternetRfpProject_Details_LocationDocuments__descriptionContent__xsIrq";
export var documentsHeading = "UserInternetRfpProject_Details_LocationDocuments__documentsHeading__mE3cU";
export var downloadButton = "UserInternetRfpProject_Details_LocationDocuments__downloadButton__in6l1";
export var fileName = "UserInternetRfpProject_Details_LocationDocuments__fileName__SxeWo";
export var fileNameAndIconWrapper = "UserInternetRfpProject_Details_LocationDocuments__fileNameAndIconWrapper__TNe3E";
export var geographicalRegion = "UserInternetRfpProject_Details_LocationDocuments__geographicalRegion__PtXRv";
export var geographicalRegionAndIconWrapper = "UserInternetRfpProject_Details_LocationDocuments__geographicalRegionAndIconWrapper__D1Yac";
export var geographicalRegionRow = "UserInternetRfpProject_Details_LocationDocuments__geographicalRegionRow__wH1tF";
export var geographicalRegionTitle = "UserInternetRfpProject_Details_LocationDocuments__geographicalRegionTitle___OVDW";
export var icon = "UserInternetRfpProject_Details_LocationDocuments__icon__IYHds";
export var panelBody = "UserInternetRfpProject_Details_LocationDocuments__panelBody__RgEuk";
export var panelContainer = "UserInternetRfpProject_Details_LocationDocuments__panelContainer__vxOmT";
export var row = "UserInternetRfpProject_Details_LocationDocuments__row__uLBsE";